<template>
	<l-map style="padding-bottom: 100%" :zoom="zoom" :center="computedCenter" ref="mapChart" @ready="onReady">
		<l-tile-layer :url="url"></l-tile-layer>
		<template :v-if="dataReady">
			<template v-for="(data, index) in chartData">
				<l-marker :key="'location' + index" :icon="blue_dot" :lat-lng="markerLatLong(data)" @click="sendPoint(index, data.timestamp_id)">
					<l-tooltip :options="tooltipOptions">{{ "timestamp:" + data.timestamp }}</l-tooltip>
				</l-marker>
			</template>
			<template v-if="showRoute">
				<l-polyline :lat-lngs="generalPoints" color="#4156F1"></l-polyline>
			</template>
		</template>
	</l-map>
</template>

<script>
import { latLngBounds } from "leaflet"
import { LMap, LTileLayer, LMarker, LTooltip, LIcon, LPolyline } from "vue2-leaflet"

export default {
	name: "map-route-chart",
	components: {
		LMap,
		LTileLayer,
		LMarker,
		LTooltip,
		LIcon,
		LPolyline,
	},
	props: {
		selectedPoint: {
			type: Object,
			required: false,
		},
		isChartDataReady: {
			type: Boolean,
		},
		defaultCenter: {
			type: Object,
			required: true,
		},
		chartData: {
			type: Array,
			required: true,
			default() {
				return []
			},
		},
		generalPoints: {
			type: Array,
			required: true,
			default() {
				return []
			},
		},
		options: {
			type: Object,
			required: false,
			default() {
				return {}
			},
		},
	},
	watch: {
		generalPoints: {
			deep: true,
			immediate: true,
			handler: function(val) {
				if (val) {
					const bounds = latLngBounds(val.lat_long)
					this.boundsUpdated(bounds)
				}
			},
		},
		selectedPoint: {
			deep: true,
			immeditate: true,
			handler: function(val) {
				if (val) {
					this.onChartInteract(val.point.timestamp_id)
				}
			},
		},
	},
	data() {
		return {
			map: null,
			url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
			zoom: 14,
			blue_dot: L.icon({
				iconUrl: "/img/map_icons/blue_dot.png",
				iconSize: [12, 12],
			}),
			tooltipOptions: {
				interactive: true,
				sticky: true,
			},
			showRoute: true,
		}
	},
	computed: {
		dataReady() {
			return this.chartData.length != 0
		},
		computedCenter() {
			if (this.dataReady) {
				if (this.selectedPoint) {
					const obj = this.chartData.find((el) => el.timestamp_id == this.selectedPoint.timestamp_id)
					if (obj) {
						return this.markerLatLong(obj)
					} else {
						const center = Math.floor(this.chartData.length / 2)
						return this.markerLatLong(this.chartData[center])
					}
				} else {
					const center = Math.floor(this.chartData.length / 2)
					return this.markerLatLong(this.chartData[center])
				}
			} else {
				return this.defaultCenter
			}
		},
	},
	methods: {
		async onChartInteract(timestamp_id) {
			this.map.closePopup()
			const obj = this.chartData.find((el) => el.timestamp_id == timestamp_id)
			if (obj) {
				this.openPopup(obj)
			}
		},
		markerLatLong(obj) {
			return L.latLng(obj.lat, obj.long)
		},
		zoomUpdated(zoom) {
			this.zoom = zoom
		},
		centerUpdated(center) {
			this.center = center
		},
		boundsUpdated(bounds) {
			this.bounds = bounds
		},
		openPopup(obj) {
			var latlng = L.latLng(obj.lat, obj.long)
			var popup = L.popup({ closeOnClick: true, closeButton: false })
				.setLatLng(latlng)
				.setContent(`timestamp: ${obj.timestamp}`)
				.openOn(this.map)
		},
		onReady() {
			this.map = this.$refs.mapChart.mapObject
		},
		sendPoint(index, timestamp_id) {
			this.$emit("point-clicked", index, timestamp_id)
		},
	},
	created() {
		this.$data
	},
}
</script>
