import Vue from "vue"
import Router from "vue-router"
import store from "@/store"

import adminRoutes from '@/router/modules/admin'
import clientRoutes from '@/router/modules/client'

const Page404 = () => import('@/views/errors/Page404')
const Page500 = () => import('@/views/errors/Page500')
const Login = () => import('@/views/auth/Login')

const redirectRequired = (to) => {
  if (store.getters['auth/isAuthenticated']) {
    return { path: `${store.getters["auth/getDashboardUrl"]}` }
  } else {
    return { path: '/login'}
  }
}

const requireUnauthenticated = (to, from, next) => {
  store.dispatch('auth/current')
    .then(() => {
      if (store.getters['auth/isAuthenticated']) {
        next('/dashboard', { replace: true })
      } else {
        next()
      }
    })
}

const redirectLogout = (to, from, next) => {
  store.dispatch('auth/logout')
    .then(() => next('/login'))
}


const baseRoutes = [
  {
    path: "/",
    redirect: redirectRequired,
  },
  {
    path: '/login',
    component: Login,
    beforeEnter: requireUnauthenticated,
  },
  {
    path: '/logout',
    beforeEnter: redirectLogout,
  },
]

const errorRoutes = [
  { path: '/500', component: Page500 },
  { path: "/:catchAll(.*)", component: Page404 },
]


const guardRoute = (to, from, next) => {
  let hasRole

  if (Array.isArray(to.meta.roleRequired)) {
    let array = to.meta.roleRequired
    hasRole = array.some((role) => {
      return store.getters["user/hasRole"](role)
    })
  } else {
    hasRole = store.getters["user/hasRole"](to.meta.roleRequired)
  }
  if (hasRole) {
    next()
  } else {
    next(false)
  }
}

const routes = baseRoutes.concat(adminRoutes, clientRoutes, errorRoutes)

Vue.use(Router)

let router = new Router({
  name: "Meds2GoVue",
  mode: "history", // https://router.vuejs.org/api/#mode
  linkActiveClass: "open active",
  scrollBehavior: () => ({ y: 0 }),
  routes: routes
})

// router.beforeEach((to, from, next) => {
//   if (to.matched.some((m) => m.meta.roleRequired)) {
//     guardRoute(to, from, next)
//   } else {
//     next()
//   }
// })


export default router
