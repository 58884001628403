import { registerComponents } from "bootstrap-vue/src/utils/plugins"

import LatestTemperatureBlocks from "./LatestTemperatureBlocks.vue"
import HighestTemperatureBlocks from "./HighestTemperatureBlocks.vue"


const components = {
	LatestTemperatureBlocks,
    HighestTemperatureBlocks
}

export default {
	install(Vue) {
		registerComponents(Vue, components)
	}
}