<template>
	<b-card class="text-center" title="HIGHEST TEMPERATURE">
		<b-row>
			<b-col v-if="settings ? settings.temperature1 : false">
				<div class="jumbotron bg-dark">
					<h5 class="text-white">TEMP 1</h5>
					<h6 class="text-white">{{ temperature1 }}</h6>
				</div>
			</b-col>
			<b-col v-if="settings ? settings.temperature2 : false">
				<div class="jumbotron bg-dark">
					<h5 class="text-white">TEMP 2</h5>
					<h6 class="text-white">{{ temperature2 }}</h6>
				</div>
			</b-col>
			<b-col v-if="settings ? settings.temperature3 : false">
				<div class="jumbotron bg-dark">
					<h5 class="text-white">TEMP 3</h5>
					<h6 class="text-white">{{ temperature3 }}</h6>
				</div>
			</b-col>
		</b-row>
	</b-card>
</template>

<script>
import { mapState } from "vuex"
export default {
	name: "highest-temperature-blocks",
	props: {
		dataCollection: {
			type: Object,
			required: false,
			default() {
				return {}
			},
		},
		settings: {
			type:Object,
			required: true,
		}
	},
	watch: {
		dataCollection: {
			deep: true,
			immediate: true,
			handler: function(val) {
				if (val) {
					this.temperature1 = val.temperature1.highest
					this.temperature2 = val.temperature2.highest
					this.temperature3 = val.temperature3.highest
				}
			},
		},
	},
	data() {
		return {
			temperature1: "N/A",
			temperature2: "N/A",
			temperature3: "N/A",
		}
	},
	computed: {
		...mapState("auth", {
			datasettings: (state) => state.current.datasetting,
		}),
	},
}
</script>

<style></style>
