import session from '@/shared/session'

const state = {
}

const getters = {
}

// actions
const actions = {
	companyDetail({commit}, id) {
		return new Promise((resolve, reject) => {
			session.get(`/api/meds2go/company/${id}/`).then(response => {
				resolve(response.data)
			}).catch(errors => {
				console.log(errors.response.data)
				reject(errors.response.data)
			})
		})
	},
	// OLDcompanyUpdate({commit}, data) {
	// 	return new Promise((resolve, reject) => {
	// 		session.patch('/api/meds2go/company/', {
	// 			params: data,
	// 		}).then(response => {
	// 			const data = response.data
	// 			resolve(data)
	// 		}).catch(errors => {
	// 			console.log(errors.response.data)
	// 			reject(errors.response.data)
	// 		})
	// 	})
	// },
	companyCreate({commit}, data) {
		return new Promise((resolve, reject) => {
			session.post('/api/meds2go/company/', data).then(response => {
				const data = response.data
				resolve(data)
			}).catch(errors => {
				console.log(errors.response.data)
				reject(errors.response.data)
			})
		})
	},
	companyRemove({commit}, data) {
		return new Promise((resolve, reject) => {
			session.patch(`/api/meds2go/company/${data.id}/`, data).then(response => {
				const data = response.data
				resolve(data)
			}).catch(errors => {
				console.log(errors.response.data)
				reject(errors.response.data)
			})
		})
	},
	companyUpdate({commit}, data) {
		return new Promise((resolve, reject) => {
			session.put(`/api/meds2go/company/${data.id}/`, data).then(response => {
				const data = response.data
				resolve(data)
			}).catch(errors => {
				console.log(errors.response.data)
				reject(errors.response.data)
			})
		})
	},
	
	filterCompanies ({ commit }, data) {
		return new Promise((resolve, reject) => {
			session.get("/api/meds2go/company/filter/",{
				params: data,
			}).then(response => {
				const data = response.data
				resolve(data)
			}).catch(errors => {
				resolve({})
			})
		})
	},

	searchCompanies({ commit }) {
		return new Promise((resolve, reject) => {
			session.get("/api/meds2go/search/company/")
				.then((response) => {
					const data = response.data
					// commit('set_search_clients', data)
					resolve(data)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
}

const mutations = {
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
