<template>
	<b-card class="mb-1 p-4" border border-variant="primary">
		<p class="mb-1">{{ title }}</p>

		<template v-if="isChartDataReady && defaultCenter != null">
			<b-button v-b-tooltip.hover title="Toggle Route" class="mb-1" ize="sm" variant="secondary" @click="toggleRoute"><i class="fa fa-road" aria-hidden="true"></i></b-button>
			<map-route-chart ref="chart" :defaultCenter="defaultCenter" :chartData="chartData" :generalPoints="generalPoints" :options="options" @point-clicked="sendPoint" :selectedPoint="selectedPoint" :isChartDataReady="isChartDataReady" />
		</template>

		<template v-else>
			<span class="text-danger"><strong>No location data for this range</strong></span>
		</template>
	</b-card>
</template>
<script>
export default {
	name: "map-route-location-chart",
	props: {
		title: {
			type: String,
			required: true,
		},
		selectedPoint: {
			type: Object,
			required: false,
		},
		isChartDataReady: {
			type: Boolean,
		},
		dataCollection: {
			type: Object,
			required: true,
			default() {
				return []
			},
		},
		isChartDataReady: {
			type: Boolean,
		},
		options: {
			type: Object,
			required: false,
			default() {
				return {}
			},
		},
	},
	watch: {
		dataCollection: {
			deep: true,
			immediate: true,
			handler: function(val) {
				if (this.isChartDataReady) {
					this.chartData = val.points
					this.generalPoints = val.general_points

					let center = Math.floor(val.points.length / 2)
					this.defaultCenter = val.points[center]
				}
			},
		},
	},
	data() {
		return {
			defaultCenter: null,
			chartData: {},
			generalPoints: {},
		}
	},
	computed: {
		showChart() {
			return this.dataCollection != {}
		},
	},
	methods: {
		sendPoint(index, timestamp_id) {
			this.$emit("point-clicked", { index: index, timestamp_id: timestamp_id })
		},
		toggleRoute() {
			this.$refs.chart.showRoute = !this.$refs.chart.showRoute
		},
	},
}
</script>
