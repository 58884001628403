<template>
	<canvas id="chart"></canvas>
</template>

<script>
export default {
	props: {
		width: {
			type: Number,
			required: false,
			default: 400,
		},
		height: {
			type: Number,
			required: false,
			default: 200,
		},
		chartType: {
			type: String,
			required: true,
		},
		data: {
			type: Object,
			required: true,
		},
		options: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			chart: null,
			chartData: {
				type: this.chartType,
				data: this.data,
				options: this.options,
			},
		}
	},
	methods: {
		renderChart() {
			var ctx = document.getElementById("chart").getContext("2d")
			this.chart = new Chart(ctx, this.chartData)
		},
	},
}
</script>
