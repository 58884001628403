import store from "@/store"

// Containers
const DefaultContainer = () => import('@/containers/DefaultContainer')
// Views
const Dashboard = () => import('@/views/Dashboard')
const ClientCharts = () => import('@/views/client/ClientCharts')
const ClientOverview = () => import('@/views/client/ClientOverview')
const ClientAdd = () => import('@/views/admin/client/ClientAdd')
const ClientEdit = () => import('@/views/admin/client/ClientEdit')

const CompanyAdd = () => import('@/views/admin/company/CompanyAdd')
const CompanyModify = () => import('@/views/admin/company/CompanyModify')
const CompanyDevices = () => import('@/views/admin/company/CompanyDevices')

const DeviceAdd = () => import('@/views/admin/device/DeviceAdd')
const DeviceEdit = () => import('@/views/admin/device/DeviceModify')
const DeviceData = () => import('@/views/admin/device/DeviceData')

const DeviceGroup = () => import('@/views/admin/device/DeviceGroup')
const DeviceGroupAdd = () => import('@/views/admin/device/DeviceGroupAdd')
const DeviceGroupDeviceAdd = () => import('@/views/admin/device/DeviceGroupDeviceAdd')


const requireStaffUser = (to, from, next) => {
  store.dispatch('auth/current')
    .then(() => {
      if (!store.getters['auth/isAuthenticated']) {
        next({ path: '/login', replace: true, query: { redirect: to.fullPath } })
      } else if (!store.getters['auth/isStaffUser']) {
        console.log('isStaffUser: ', store.getters['auth/isStaffUser'])
        next({ path: from.fullPath, replace: true})
      } else {
          next()
      }
    })
}

export default [
  {
    path: "/dashboard/",
    component: DefaultContainer,
    meta: {
      label: "Dashboard",
    },
    children: [
      {
        path: "",
        component: Dashboard,
        beforeEnter: requireStaffUser,
        meta: {
          tag: 'dashboard',
          label: "Dashboard",
        },
      },
      {
        path: "company/add",
        beforeEnter: requireStaffUser,
        component: CompanyAdd,
        meta: {
          tag: 'company',
          label: "Create Company",
        },
      },
      {
        path: 'company/:companyId/details',
        component: CompanyModify,
        beforeEnter: requireStaffUser,
        props: true,
        meta: {
          label: "Company Details",
          tag: 'company',
        }
      },
      {
        path: 'company/:companyId/devices',
        name: 'Company Devices',
        component: CompanyDevices,
        beforeEnter: requireStaffUser,
        props: true,
        meta: {
          tag: 'company',
          label: 'Company Devices',
        }
      },
      {
        path: 'client/overview',
        component: ClientOverview,
        beforeEnter: requireStaffUser,
        meta: {
          tag: 'client',
          label: 'Clients Overview',
        }
      },
      {
        path: 'client/add',
        component: ClientAdd,
        beforeEnter: requireStaffUser,
        meta: {
          tag: 'client',
          label: 'Create Client',
        }
      },
      {
        path: 'client/edit/:clientId',
        component: ClientEdit,
        beforeEnter: requireStaffUser,
        props: true,
        meta: {
          tag: 'client',
        	label: 'Edit Client',
        }
      },
      {
        path: 'client/charts/:deviceId',
        component: ClientCharts,
        beforeEnter: requireStaffUser,
        props: true,
        meta: {
          tag: 'client',
        	label: 'Chart Dashboard',
        }
      },

      // DEVICE //

      {
        path: 'device/add',
        component: DeviceAdd,
        beforeEnter: requireStaffUser,
        meta: {
        	label: 'Create Device',
          tag: 'device',
        }
      },
      {
        path: 'device/:deviceId/modify',
        component: DeviceEdit,
        beforeEnter: requireStaffUser,
        props: true,
        meta: {
        	label: 'Edit Device',
          tag: 'device',
        }
      },
      {
        path: 'device/data/latest',
        component: DeviceData,
        beforeEnter: requireStaffUser,
        props: true,
        meta: {
          label: 'Latest Device Data',
          tag: 'device',
        }
      },

      // DEVICEGROUP //
      
      {
        path: 'devicegroup/add',
        component: DeviceGroup,
        beforeEnter: requireStaffUser,
        meta: {
        	label: 'Create DeviceGroup',
          tag: 'devicegroup',
        }
      },
      {
        path: 'devicegroup/:companyId/add',
        component: DeviceGroup,
        beforeEnter: requireStaffUser,
        props: true,
        meta: {
        	label: 'Add DeviceGroup',
          tag: 'devicegroup',
        }
      },
      {
        path: 'devicegroup/device/:groupId/add',
        component: DeviceGroupDeviceAdd,
        beforeEnter: requireStaffUser,
        props: true,
        meta: {
        	label: 'Add Device to Company',
          tag: 'devicegroup',
        }
      },
      {
        path: 'devicegroup/device/:deviceGroupId/new',
        component: DeviceAdd,
        beforeEnter: requireStaffUser,
        props: true,
        meta: {
        	label: 'Create new Device for Company',
          tag: 'devicegroup',
        }
      },

    ]
  },
]