import session from '@/shared/session'
import axios from 'axios'
const state = {
}

const getters = {
}

// actions
const actions = {
	clientDashboard({commit}) {
		return new Promise((resolve, reject) => {
			session.get('/api/meds2go/dashboard/').then(response => {
				const data = response.data
				resolve(data)
			}).catch(errors => {
				console.log(errors.response.data)
				reject(errors.response.data)
			})
		})
	},
	clientDetail({commit}, id) {
		return new Promise((resolve, reject) => {
			session.get(`/api/meds2go/client/${id}/`).then(response => {
				const data = response.data
				resolve(data)
			}).catch(errors => {
				console.log(errors.response.data)
				reject(errors.response.data)
			})
		})
	},
	clientDevices({commit}) {
		return new Promise((resolve, reject) => {
			session.get('/api/meds2go/client/').then(response => {
				const data = response.data
				resolve(data)
			}).catch(errors => {
				console.log(errors.response.data)
				reject(errors.response.data)
			})
		})
	},
	clientCreate({commit}, data) {
		return new Promise((resolve, reject) => {
			session.post('/api/meds2go/client/', {
				data,
			}).then(response => {
				const data = response.data
				resolve(data)
			}).catch(errors => {
				console.log(errors.response.data)
				reject(errors.response.data)
			})
		})
	},
	clientUpdate({commit}, data) {
		return new Promise((resolve, reject) => {
			session.put(`/api/meds2go/client/${data.id}/`, data).then(response => {
				const data = response.data
				resolve(data)
			}).catch(errors => {
				console.log(errors.response.data)
				reject(errors.response.data)
			})
		})
	},
	clientRemove({commit}, data) {
		return new Promise((resolve, reject) => {
			session.patch(`/api/meds2go/client/${data.id}/`, data).then(response => {
				const data = response.data
				resolve(data)
			}).catch(errors => {
				console.log(errors.response.data)
				reject(errors.response.data)
			})
		})
	},
	// dataSettings({ commit }) {
	// 	return new Promise((resolve, reject) => {
	// 		session.get("/api/meds2go/client/settings/")
	// 		.then(response => {
	// 			const data = response.data
	// 			resolve(data)
	// 		}).catch(errors => {
	// 			resolve({})
	// 		})
	// 	})
	// },
	filterClients ({ commit }, data) {
		return new Promise((resolve, reject) => {
			session.get("/api/meds2go/client/filter/",{
				params: data,
			}).then(response => {
				const data = response.data
				resolve(data)
			}).catch(errors => {
				resolve({})
			})
		})
	},

	searchClients({ commit }) {
		return new Promise((resolve, reject) => {
			session.get("/api/meds2go/search/client/")
				.then((response) => {
					const data = response.data
					// commit('set_search_clients', data)
					resolve(data)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
}

const mutations = {
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
