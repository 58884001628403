import { registerComponents } from "bootstrap-vue/src/utils/plugins"

import DateTimeRangePicker from "./DateTimeRangePicker";

const components = {
	DateTimeRangePicker
}

export default {
	install(Vue) {
		registerComponents(Vue, components)
	}
}