<template>
	<b-card class="mb-1" border border-variant="primary">
		<b-button v-b-tooltip.hover title="Reset Zoom" class="ml-4" ize="sm" variant="secondary" @click="resetZoom"><i class="fa fa-search-minus" aria-hidden="true"></i></b-button>
		<line-chart ref="chart" chartType="line" :data="datasets" :options="options" :selectedPoint="selectedPoint" />
	</b-card>
</template>
<script>
export default {
	name: "multi-dataset-line-chart",
	props: {
		dataCollection: {
			type: Object,
			required: true,
		},
		chartType: {
			type: String,
			required: false,
			default() {
				return "line"
			},
		},
		selectedPoint: {
			type: Object,
			required: false,
		},
	},
	watch: {
		dataCollection: {
			deep: true,
			immediate: true,
			handler: function(val) {
				if (this.isObject(val)) {
					this.datasets = val
				}
			},
		},
	},
	data() {
		return {
			datasets: [],
			options: {
				animation: false,
				events: ["click"],
				responsive: true,
				interaction: {
					mode: "index",
					intersect: false,
				},
				parsing: {
					xAxisKey: "timestamp",
					yAxisKey: "value",
				},
				scales: {
					yAxis1: {
						type: "linear",
						position: "left",
					},
					yAxis2: {
						type: "linear",
						position: "right",
						offset: true,
						beginAtZero: true,
						min: 0,
						max: 6,
						ticks: {
							callback: function(value, index, values) {
								switch (value) {
									case 0:
										return "Unknown"
										break
									case 1:
										return "Lock:Opened"
										break
									case 4:
										return "Orientation:Upsidedown"
										break
									case 5:
										return "Orientation:Sideways"
										break
									default:
										break
								}
							},
						},
					},
				},
				plugins: {
					tooltip: {
						enabled: true,
						position: "custom",
						callbacks: {
							label: function(tooltipItem, data) {
								let value = tooltipItem.parsed.y

								if (tooltipItem.dataset.label.toLowerCase() == "lock") {
									switch (value) {
										case 0:
											return tooltipItem.dataset.label + ": Unknown"
											break
										case 1:
											return tooltipItem.dataset.label + ": Opened"
											break
										case 2:
											return tooltipItem.dataset.label + ": Closed"
											break
										default:
											break
									}
								} else if (tooltipItem.dataset.label.toLowerCase() == "orientation") {
									switch (value) {
										case 3:
											return tooltipItem.dataset.label + ": Normal"
											break
										case 4:
											return tooltipItem.dataset.label + ": Upsidedown"
											break
										case 5:
											return tooltipItem.dataset.label + ": Sideways"
											break
										default:
											break
									}
								} else {
									return tooltipItem.dataset.label + ": " + value
								}
							},
						},
					},
					zoom: {
						pan: {
							enabled: true,
							mode: "x",
							modifierKey: "ctrl",
						},
						zoom: {
							drag: {
								enabled: true,
								backgroundColor: "rgb(37, 50, 55, 0.5)",
								borderColor: "rgb(37, 50, 55)",
								borderWidth: 1,
							},
							wheel: {
								enabled: false,
							},
							mode: "x",
						},
					},
				},
				onClick: (data, activePoints) => {
					let index = activePoints[0].index
					const timestamp_id = this.datasets.datasets[0].data[index].timestamp_id
					this.$emit("point-clicked", { index: index, timestamp_id: timestamp_id })
				},
			},
		}
	},
	methods: {
		resetZoom() {
			this.$refs.chart.chart.resetZoom()
		},
	},
}
</script>
