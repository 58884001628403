<template>
	<div>
		<b-row>
			<b-col sm="11">
				<date-time-range-picker ref="datepicker" :startDate="range.start" :endDate="range.end" @trigger-date-range-change="triggerRangeRefresh" />
			</b-col>
			<b-col>
				<div class="float-right">
					<b-button v-if="showNewData" v-b-tooltip.hover title="Retrieve new  Data" size="md" variant="success" @click="retrieveNewData"> <i class="fa fa-refresh mr-1" aria-hidden="true"></i> New Data </b-button>
					<b-button v-else v-b-tooltip.hover title="Refresh Data" size="md" variant="secondary" @click="triggerRefresh"><i class="fa fa-refresh" aria-hidden="true"></i></b-button>
				</div>
			</b-col>
		</b-row>
	</div>
</template>

<script>
export default {
	name: "chart-toolbar",
	props: {
		range: {
			type: Object,
			required: true,
		},
		showNewData: {
			type: Boolean,
			required: false,
		},
	},
	data() {
		return {}
	},
	methods: {
		triggerRangeRefresh(range) {
			this.$emit("refresh", range)
		},
		triggerRefresh() {
			const start = this.$refs.datepicker.range.start.toISOString()
			const end = this.$refs.datepicker.range.end.toISOString()
			const range = { start: start, end: end }
			this.$emit("refresh", range)
		},
		retrieveNewData(){
			this.$emit("retrieve")
		}
	},
}
</script>

<style></style>
