import moment from 'moment'
export default {
	methods: {
		timestampToFormattedDatetime(timestamp) {
			let options = { weekday: "short", year: "numeric", month: "short", day: "numeric", hour: "2-digit", minute: "2-digit" }
			return new Date(timestamp * 1000).toLocaleDateString("gb-GB", options)
		},
		formattedDateTime(datetime) {
			let options = { weekday: "short", year: "numeric", month: "short", day: "numeric", hour: "2-digit", minute: "2-digit" }
			return new Date(datetime).toLocaleDateString("gb-GB", options)
		},
		formattedShortDateTime(datetime) {
			let options = {year: "numeric", month: "short", day: "numeric", hour: "2-digit", minute: "2-digit" }
			return new Date(datetime).toLocaleDateString("gb-GB", options)
		},
		formattedWeekFullDate(date) {
			return moment(date).locale('nl').format('ddd DD MMMM YYYY')
			// return moment(date).locale('nl').format('dddd DD MMMM YYYY')
			// return moment(date).format('dddd DD MMMM YYYY')
			// let options = { year: "numeric", month: "long", day: "numeric" }
			// return new Date(date).toLocaleDateString("nl-NL", options)
		},
		formattedFullDate(date) {
			return moment(date).locale('nl').format('DD MMMM YYYY')
			// return moment(date).locale('nl').format('dddd DD MMMM YYYY')
			// return moment(date).format('dddd DD MMMM YYYY')
			// let options = { year: "numeric", month: "long", day: "numeric" }
			// return new Date(date).toLocaleDateString("nl-NL", options)
		},
		formattedDate(date) {
			let options = { year: "numeric", month: "short", day: "numeric" }
			return new Date(date).toLocaleDateString("nl-NL", options)
		},
		formattedWeekday(date) {
			let options = { weekday: "long" }
			return new Date(date).toLocaleDateString("nl-NL", options)
		},
		formatBinaryStatusValues(source, status){
			if (source == "lock") {
				switch (status) {
					case 0:
						return "Unknown"
						break
					case 1:
						return "Opened"
						break
					case 2:
						return "Closed"
						break
				}
			} else if (source == "orientation") {
				switch (status) {
					case 0:
						return "Normal"
						break
					case 1:
						return "Upsidedown"
						break
					case 2:
						return "Sideways"
						break
				}
			} else {
				return status
			}
		},
	    isObject(obj) {
	        return Object.keys(obj).length > 0
	    },
		toast(title, message, variant, sticky = false, delay = 3000, hideCloseButton = true) {
			this.$bvToast.toast(message, {
				solid: true,
				title: title,
				variant: variant,
				noAutoHide: sticky,
				autoHideDelay: delay,
				noCloseButton: hideCloseButton,
			})
		},
	}
}
