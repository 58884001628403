import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth'
import common from './modules/common'
import meds2go from './modules/meds2go'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		auth,
		common,
		meds2go
	}
})
