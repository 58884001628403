<template>
	<b-form @submit.prevent>
		<div>
			<v-date-picker v-model="range" mode="dateTime" :masks="masks" :timezone="timezone" :max-date="new Date()" is-range>
				<template v-slot="{ inputValue, inputEvents, isDragging }">
					<b-input-group>
						<template #prepend>
							<b-input-group-text><i class="fa fa-calendar" aria-hidden="true"></i></b-input-group-text>
						</template>
						<b-form-input readonly :class="isDragging ? 'form-control text-gray-600' : 'form-control text-gray-900'" :value="computeDateRangeString(inputValue)" v-on="inputEvents.start" />
						<template #append>
							<b-button size="md" variant="success" @click="triggerRangeChange()">Apply Time Range</b-button>
						</template>
					</b-input-group>
				</template>
			</v-date-picker>
		</div>
	</b-form>
</template>

<script>
import VCalendar from "v-calendar"

export default {
	name: "date-time-range-picker",
	components: {
		VCalendar,
	},
	props: {
		startDate: {
			type: String,
			default() {
				const today = new Date()
				return new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1).toISOString()
			},
		},
		endDate: {
			type: String,
			default() {
				return new Date().toISOString()
			},
		},
	},
	data() {
		return {
			range: {
				start: new Date(this.startDate),
				end: new Date(this.endDate),
			},
			masks: {
				inputDateTime: "DD/MM/YYYY h:mm A",
			},
			timezone: "",
		}
	},
	methods: {
		computeDateRangeString(inputValue) {
			return inputValue.start + " -> " + inputValue.end
		},
		triggerRangeChange() {
			this.$emit("trigger-date-range-change", { start: this.range.start.toISOString(), end: this.range.end.toISOString() })
		},
	},
}
</script>

<style></style>
