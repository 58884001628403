import axios from 'axios'
import store from '@/store'


const CSRF_COOKIE_NAME = 'csrftoken'
const CSRF_HEADER_NAME = 'X-CSRFToken'

const session = axios.create({
  xsrfCookieName: CSRF_COOKIE_NAME,
  xsrfHeaderName: CSRF_HEADER_NAME,
})


// session.interceptors.response.use((err) => {
//   // if error response status is 401, it means the request was invalid due to expired access token
//   if (err.config && err.response && err.response.status === 403) {
//   	return Promise.resolve({})
//   }
// })

export default session