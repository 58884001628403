import session from '@/shared/session'
// initial state
const state = {
	current : {},
	user_groups: [],
}

// getters
const getters = {
	isAuthenticated: state => Object.entries(state.current).length !== 0,
	isSuperUser: state => state.current.is_superuser,
	isStaffUser: state => state.current.is_staff,

	getclientUserGroup: state => {
      for (let x in state.user_groups){
        if (state.user_groups[x].name == "client"){
          return state.user_groups[x].id
        }
      }
    },
    
	getDashboardUrl: (state, getters) => {
		if (state.current){
			if(state.current.groups.includes(getters.getclientUserGroup)){
				// console.log("client dashboard")
	          	return'/clientdashboard'
	          }
      	}
		return '/dashboard'
	},
	getAccessibleDeviceIds: state => {
		let idList = new Array()
		for (let x in state.current.access_devices_options){
			idList.push(state.current.access_devices_options[x]['key'])
		}
		return idList
	}
}

// actions
const actions = {
	current ({ commit }) {
		return new Promise((resolve, reject) => {
			session.get('/api/auth/current/')
			.then(response => {
				const user = response.data
				commit('set_user', user)
				resolve(user)

			}).catch(errors => {
				// unauthorized or forbidden error, or django session expired, reset user
				commit('set_user', {})
				resolve({})
			})
		})
	},

	getUserGroups ({ commit }) {
		return new Promise((resolve, reject) => {
			session.get('/api/auth/user_groups/')
			.then(response => {
				const user_groups = response.data
				commit('set_user_groups', user_groups)
				resolve(user_groups)

			}).catch(errors => {
				// unauthorized or forbidden error, or django session expired, reset user
				commit('set_user_groups', [])
				resolve({})
			})
		})
	},

	login ({ commit }, credentials) {
		return new Promise((resolve, reject) => {
            session.post('/api/auth/login/', credentials)
                .then(response => {
					const user = response.data
					commit('set_user', user)
					resolve(user)
                })
                .catch(errors => {
                    reject(errors.response.data);
                });
		})
	},

	logout ({ commit }) {
		return new Promise((resolve, reject) => {
			session.post('/api/auth/logout/')
			.then(response => {
				commit('set_user', {})
				resolve()
			})
			.catch(errors => {
				reject(errors.response.data)
			})

		})
	}
}

// mutations
const mutations = {
	set_user (state, user) {
		state.current = user
	},
	set_user_groups (state, userGroups) {
		state.user_groups = userGroups
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}