import store from "@/store"

// Containers
const DefaultContainer = () => import('@/containers/DefaultContainer')
// Views
const ClientDashboard = () => import('@/views/client/ClientDashboard')
const ClientCharts = () => import('@/views/client/ClientCharts')

const requireAuthenticated = (to, from, next) => {
  store.dispatch('auth/current')
    .then(() => {
      if (!store.getters['auth/isAuthenticated']) {
        next({ path: '/login', replace: true, query: { redirect: to.fullPath } })
      } else {
        next()
      }
    })
}


export default [
  {
    path: "/ClientDashboard",
    component: DefaultContainer,
    meta: {
      label: "Client Dashboard",
    },
    children: [
      {
        path: "",
        component: ClientDashboard,
        beforeEnter: requireAuthenticated,
        meta: {
        	tag: 'client',
          label: "Client Dashboard",
        },
      },
      {
        path: "client/charts/:deviceId",
        component: ClientCharts,
        beforeEnter: requireAuthenticated,
        props: true,
        meta: {
					tag: 'client',
          label: "Client Charts",
        },
      },
    ]
  },
]