import session from '@/shared/session'
import axios from 'axios'
const state = {
	access_button: false,
	from_login: false,
	devicegroups: [],
}

const getters = {
}

// actions
const actions = {
	accessButtons({commit}, data){
		return new Promise((resolve, reject) => {
			commit('set_access_button', data)
			resolve(null)
			})
	},
	setFromLogin({commit}, data){
		return new Promise((resolve, reject) => {
			commit('set_fromLogin', data)
			resolve(null)
			})
	},
	generateCode ({commit}, data) {
		return new Promise((resolve, reject) => {
			session.get('/api/meds2go/generate_code/', {
				params: data,
			})
			.then(response => {
				const data = response.data
				resolve(data)
			}).catch(errors => {
				console.log(errors.response.data)
				reject(errors.response.data)
			})
		})
	},	
	resetCurrentPeriod({commit}, data) {
		return new Promise((resolve, reject) => {
			commit('set_period', null)
			resolve(null)
		})
	},
	logGrafanaAccess({ commit}, data) {
		console.log("data: ", data)
		return new Promise((resolve, reject) => {
			session.post('/api/meds2go/grafanalog/', data)
				.then((response) => {
					const data = response.data
					resolve(data)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	}
	// loginToGrafana({ commit }) {
	// 	return new Promise((resolve, reject) => {
	// 		const grafana = axios.create({
	// 		  headers: {'X-WEBAUTH-USER': 'admin', 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Credentials':true, 'Access-Control-Allow-Headers': 'Authorization, Content-Type, origin, accept', 'Access-Control-Allow-Methods': 'GET'}
	// 		})

	// 		grafana.get("http://192.168.50.17:8081/")
	// 			.then((response) => {
	// 				const data = response.data
	// 				// commit('set_search_clients', data)
	// 				resolve(data)
	// 			})
	// 			.catch((errors) => {
	// 				reject(errors.response.data)
	// 			})
	// 	})
	// },
	

}

const mutations = {
	set_access_button(state, canAccess){
		state.access_button = canAccess
	},
	set_fromLogin(state, data){
		state.from_login = data
	},

}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
