import Errors from './Errors'

class Form {
    /**
     * Create a new Form instance.
     *
     * @param {object} data
     */
    constructor(data) {
        this.originalData = data;

        for (let field in data) {
            this[field] = data[field];
        }

        this.errors = new Errors();
    }

    set(data, exclude=[]) {
        if(data) {
            Object.keys(data).map((property) => {
                if(!exclude.includes(property)) {
                    if(data[property] != null) {
                        if (property in this.originalData) {
                            this[property] = data[property]
                        }
                    }
                }
            })
        }else{
            this.reset()
        }
    }

    /**
     * Fetch all relevant data for the form.
     */
    data() {
        let data = {};

        for (let property in this.originalData) {
            data[property] = this[property];
        }

        return data;
    }

    /**
     * Reset the form fields with default data.
     */
    reset(exclude=[]) {

        for (let field in this.originalData) {
            if(!exclude.includes(field)) {
                this[field] = this.originalData[field];
            }
        }
        this.errors.clear();
    }

    /**
     * Set the form fields with default data.
     */
    default(data) {
        Object.keys(data).map((property) => {
            if (property in this.originalData) {
                this[property] = data[property]
            }
        })
        
        this.errors.clear();
    }
}

export default Form