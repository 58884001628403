import { registerComponents } from "bootstrap-vue/src/utils/plugins"

import ChartToolbar from "./ChartToolbar.vue"
import LineChart from "./custom-charts/LineChart.vue"
import MapPointChart from "./custom-charts/MapPointChart.vue"
import MapRouteChart from "./custom-charts/MapRouteChart.vue"

import MapPointLocationChart from './MapPointLocationChart.vue'
import MapRouteLocationChart from './MapRouteLocationChart.vue'
import MultiDatasetLineChart from './MultiDatasetLineChart.vue'

const components = {
	ChartToolbar,
	LineChart,
	MapPointChart,
	MapRouteChart,
	MapPointLocationChart,
	MapRouteLocationChart,
	MultiDatasetLineChart
}

export default {
	install(Vue) {
		registerComponents(Vue, components)
	}
}