// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
// import 'core-js/es6/promise'
// import 'core-js/es6/string'
// import 'core-js/es7/array'
// import cssVars from 'css-vars-ponyfill'
import Vue from 'vue'
import mixins from '@/shared/mixins'
import VueMoment from 'vue-moment'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import { BToast } from 'bootstrap-vue'
import Blocks from "./components/blocks"
import CustomCharts from "./components/charts"
import CustomDatePickers from "./components/datepicker";
import App from './App'
import Form from '@/shared/Form'
import Errors from '@/shared/Errors'
import router from './router'
import store from './store'
import VCalendar from 'v-calendar'

import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'

import Chart from "chart.js/auto"
import zoomPlugin from 'chartjs-plugin-zoom'
Chart.register(zoomPlugin)

const tooltipPlugin = Chart.registry.getPlugin("tooltip")
tooltipPlugin.positioners.custom = function(elements, eventPostion) {
	if (eventPostion.hasOwnProperty('x')){
		return {x:eventPostion.x, y:eventPostion.y}
	} else {
		return eventPostion
	}

}


import { Icon } from "leaflet"
import 'leaflet/dist/leaflet.css'; 

delete Icon.Default.prototype._getIconUrl

Icon.Default.imagePath = '.';
Icon.Default.mergeOptions({
	iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
	iconUrl: require("leaflet/dist/images/marker-icon.png"),
	shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
})

const moment = extendMoment(Moment);

window.Chart = Chart
window.Form = Form
window.Errors = Errors

Vue.mixin(mixins)

Vue.use(VueMoment)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(Blocks)
Vue.use(CustomCharts)
Vue.use(VCalendar)
Vue.use(CustomDatePickers)


Vue.config.productionTip = false

Vue.component('vue-typeahead-bootstrap', VueTypeaheadBootstrap)
Vue.component('b-toast', BToast)

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app")