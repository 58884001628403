<script>
import BaseChart from "../src/BaseCharts.vue"

export default {
	name: "line-chart",
	extends: BaseChart,
	props: {
		selectedPoint: {
			type: Object,
			required: false,
		},
	},
	data() {
		return {
			needsUpdate: false,
		}
	},
	watch: {
		selectedPoint: {
			deep: true,
			handler: function(val) {
				this.onChartInteract(val.source, val.point.timestamp_id)
			},
		},
		chartData: {
			deep: true,
			handler: function(val) {
				if (this.needsUpdate == false) {
					this.needsUpdate = true
				}
			},
		},
		needsUpdate: {
			immediate: true,
			handler: function(val) {
				if (val) {
					this.needsUpdate = false
				}
			},
		},
	},
	methods: {
		async onChartInteract(source, timestampId) {
			await this.showTooltip(source, timestampId)
		},
		getSourceIndex(obj, source) {
			let index = obj.findIndex((element) => element.source == source)
			return obj[index]
		},
		showTooltip(source, timestampId) {
			if (source != "temp1") {
				this.chart.resetZoom()
			}

			const datasets = this.chart._metasets
			const activeTooltipElements = []
			const activeTooltipElementsPostions = []

			for (let i = 0; i < datasets.length; i++) {
				const requestedElements = this.chart.getDatasetMeta(i)._dataset.data

				const pointIndex = requestedElements.findIndex((element) => element.timestamp_id == timestampId)

				const elementPositionX = this.chart.getDatasetMeta(i).data[pointIndex].x
				const elementPositionY = this.chart.getDatasetMeta(i).data[pointIndex].y

				activeTooltipElementsPostions.push({ source: this.chart.getDatasetMeta(i).label.toLowerCase(), x: elementPositionX, y: elementPositionY })
				activeTooltipElements.push({ datasetIndex: i, index: pointIndex })
			}

			this.chart.tooltip.setActiveElements(activeTooltipElements, this.getSourceIndex(activeTooltipElementsPostions, source))
			this.chart.draw()
		},
	},
	mounted() {
		this.renderChart()
	},
}
</script>
