import session from '@/shared/session'

const state = {
	devicegroups: [],
}

const getters = {
}

// actions
const actions = {
	deviceDetail({commit}, id) {
		return new Promise((resolve, reject) => {
			session.get(`/api/meds2go/device/${id}/`).then(response => {
				const data = response.data
				resolve(data)
			}).catch(errors => {
				console.log(errors.response.data)
				reject(errors.response.data)
			})
		})
	},
	filterNewData({commit}, data) {
		return new Promise((resolve, reject) => {
			session.get(`/api/meds2go/device/data/filter/`,{
				params: data
			}).then(response => {
				const data = response.data
				resolve(data)
			}).catch(errors => {
				console.log(errors.response.data)
				reject(errors.response.data)
			})
		})
	},
	deviceUpdate({commit}, data) {
		return new Promise((resolve, reject) => {
			session.put(`/api/meds2go/device/${data.id}/`, data).then(response => {
				const data = response.data
				resolve(data)
			}).catch(errors => {
				console.log(errors.response.data)
				reject(errors.response.data)
			})
		})
	},
	devicePartial({commit}, data) {
		return new Promise((resolve, reject) => {
			session.patch(`/api/meds2go/device/${data.id}/`, data).then(response => {
				const data = response.data
				resolve(data)
			}).catch(errors => {
				console.log(errors.response.data)
				reject(errors.response.data)
			})
		})
	},
	deviceUpdateGroup({commit}, data) {
		return new Promise((resolve, reject) => {
			session.put(`/api/meds2go/device/${data.id}/`, data).then(response => {
				const data = response.data
				resolve(data)
			}).catch(errors => {
				console.log(errors.response.data)
				reject(errors.response.data)
			})
		})
	},
	deviceCreate({commit}, data) {
		return new Promise((resolve, reject) => {
			session.post('/api/meds2go/device/',
				data).then(response => {
				const data = response.data
				resolve(data)
			}).catch(errors => {
				console.log(errors.response.data)
				reject(errors.response.data)
			})
		})
	},
	
	filterDevices ({ commit }, data) {
		return new Promise((resolve, reject) => {
			session.get("/api/meds2go/device/filter/",{
				params: data
			}).then(response => {
				const data = response.data
				resolve(data)
			}).catch(errors => {
				resolve({})
			})
		})
	},

	searchDevices({ commit }) {
		return new Promise((resolve, reject) => {
			session.get("/api/meds2go/search/device/")
				.then((response) => {
					const data = response.data
					// commit('set_search_clients', data)
					resolve(data)
				})
				.catch((errors) => {
					reject(errors.response.data)
				})
		})
	},
	getCompanyDevices ({commit}, companyId) {
		return new Promise((resolve, reject) => {
			session.get(`/api/meds2go/company/devices/${companyId}/`)
			.then(response => {
				const data = response.data
				resolve(data)
			}).catch(errors => {
				console.log(errors)
				reject(errors.response.data)
			})
		})
	},
	getCompanyDeviceGroups ({commit}, companyId) {
		return new Promise((resolve, reject) => {
			session.get(`/api/meds2go/company/devicegroups/${companyId}/`)
			.then(response => {
				const data = response.data
				// commit('set_devicegroups', data)
				resolve(data)
			}).catch(errors => {
				console.log(errors)
				reject(errors.response.data)
			})
		})
	},
	getDeviceGroups ({commit}) {
		return new Promise((resolve, reject) => {
			session.get('/api/meds2go/devicegroups/')
			.then(response => {
				const data = response.data
				commit('set_devicegroups', data)
				resolve(data)
			}).catch(errors => {
				console.log(errors)
				reject(errors.response.data)
			})
		})
	},
	deviceGroupDetail({commit}, id) {
		return new Promise((resolve, reject) => {
			session.get(`/api/meds2go/devicegroup/${id}/`).then(response => {
				const data = response.data
				resolve(data)
			}).catch(errors => {
				console.log(errors.response.data)
				reject(errors.response.data)
			})
		})
	},
	deviceGroupCreate({commit}, data) {
		return new Promise((resolve, reject) => {
			session.post('/api/meds2go/devicegroup/',
				data).then(response => {
				const data = response.data
				resolve(data)
			}).catch(errors => {
				reject(errors.response.data)
			})
		})
	},
	deviceChartData({commit}, data) {
		return new Promise((resolve, reject) => {
			session.get(`/api/meds2go/charts/query/${data.id}/`, {
				params: data
			}).then(response => {
				const data = response.data
				resolve(data)
			}).catch(errors => {
				console.log(errors.response.data)
				reject(errors.response.data)
			})
		})
	},

	checkNewChartData({commit}, id) {
		return new Promise((resolve, reject) => {
			session.get(`/api/meds2go/charts/query/new/${id}/`).then(response => {
				const data = response.data
				resolve(data)
			}).catch(errors => {
				console.log(errors.response.data)
				reject(errors.response.data)
			})
		})
	},
}

const mutations = {
	set_devicegroups(state, groups){
		state.devicegroups = groups
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
