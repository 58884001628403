import session from '@/shared/session'

const state = {
}

const getters = {
}

// actions
const actions = {
	getCompanies ({commit}, data) {
		return new Promise((resolve, reject) => {
			session.get('/api/meds2go/company/', {
				params: data,
			})
			.then(response => {
				const data = response.data
				resolve(data)
			}).catch(errors => {
				console.log(errors.response.data)
				reject(errors.response.data)
			})
		})
	},
}

const mutations = {
}

import device from './meds2go/device'
import client from './meds2go/client'
import company from './meds2go/company'
const modules = {
		device,
		client,
		company
	}

export default {
	namespaced: true,
	modules,
	state,
	getters,
	actions,
	mutations
}
