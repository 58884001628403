<template>
	<b-card class="mb-1" border border-variant="primary">
		<p>{{ title }}</p>

		<template v-if="isChartDataReady && defaultCenter != null">
			<map-point-chart :mapType="mapType" :defaultCenter="defaultCenter" :genereicPoints="genereicPoints" :chartData="chartData" :options="options" @point-clicked="sendPoint" :selectedPoint="selectedPoint" :isChartDataReady="isChartDataReady" />
		</template>

		<template v-else>
			<span class="text-danger"><strong>No location data for this range</strong></span>
		</template>
	</b-card>
</template>
<script>
export default {
	name: "map-point-location-chart",
	props: {
		title: {
			type: String,
			required: true,
		},
		showStatus: {
			type: String,
			required: true,
		},
		default_status: {
			type: String,
			required: false,
		},
		mapType: {
			type: String,
			required: true,
		},
		selectedPoint: {
			type: Object,
			required: false,
		},
		isChartDataReady: {
			type: Boolean,
		},
		dataCollection: {
			type: Object,
			required: true,
			default() {
				return []
			},
		},
		options: {
			type: Object,
			required: false,
			default() {
				return {}
			},
		},
	},
	watch: {
		dataCollection: {
			deep: true,
			immediate: true,
			handler: function(val) {
				if (this.isChartDataReady) {
					this.chartData = val[this.showStatus]
					this.genereicPoints = val["general_points"].length != 0 ? val["general_points"] : null

					let centerIndex = null
					let centerData = null

					if (this.mapType == "lock") {
						if (val.all.length != 0) {
							centerIndex = Math.floor(val.all.length / 2)
							centerData = val.all[centerIndex]
						} else if (val.open.length != 0) {
							centerIndex = Math.floor(val.open.length / 2)
							centerData = val.open[centerIndex]
						}
					} else if (this.mapType == "orientation") {
						if (val.upsidedown.length != 0) {
							centerIndex = Math.floor(val.upsidedown.length / 2)
							centerData = val.upsidedown[centerIndex]
						} else if (val.normal.length != 0) {
							centerIndex = Math.floor(val.normal.length / 2)
							centerData = val.normal[centerIndex]
						} else if (val.sideways.length != 0) {
							centerIndex = Math.floor(val.sideways.length / 2)
							centerData = val.sideways[centerIndex]
						}
					}
					this.defaultCenter = centerData != null ? [centerData.lat, centerData.long] : null
				}
			},
		},
	},
	data() {
		return {
			defaultCenter: null,
			chartData: [],
			genereicPoints: [],
		}
	},
	methods: {
		sendPoint(index, timestamp_id) {
			this.$emit("point-clicked", { index: index, timestamp_id: timestamp_id })
		},
	},
}
</script>
