<template>
	<b-card class="text-center" title="LATEST TEMPERATURE">
		<b-row>
			<b-col v-if="settings.temperature1">
				<div class="jumbotron bg-dark">
					<h5 class="text-white">TEMP 1</h5>
					<h6 class="text-white">{{ temperature1 }}</h6>
				</div>
			</b-col>
			<b-col v-if="settings.temperature2">
				<div class="jumbotron bg-dark">
					<h5 class="text-white">TEMP 2</h5>
					<h6 class="text-white">{{ temperature2 }}</h6>
				</div>
			</b-col>
			<b-col v-if="settings.temperature3">
				<div class="jumbotron bg-dark">
					<h5 class="text-white">TEMP 3</h5>
					<h6 class="text-white">{{ temperature3 }}</h6>
				</div>
			</b-col>
		</b-row>
	</b-card>
</template>

<script>
import { mapState } from "vuex"
export default {
	name: "latest-temperature-blocks",
	props: {
		dataCollection: {
			type: Object,
			required: false,
			default() {
				return {}
			},
		},
		settings: {
			type:Object,
			required: true,
		}
	},
	watch: {
		dataCollection: {
			deep: true,
			immediate: true,
			handler: function(val) {
				if (val) {
					this.temperature1 = val.temperature1.latest
					this.temperature2 = val.temperature2.latest
					this.temperature3 = val.temperature3.latest
				}
			},
		},
	},
	data() {
		return {
			temperature1: "N/A",
			temperature2: "N/A",
			temperature3: "N/A",
		}
	},
	computed: {
		...mapState("auth", {
			datasettings: (state) => state.current.datasetting,
		}),
	},
}
</script>

<style></style>
